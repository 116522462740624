import { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css'
import { IframeProvider } from './context/IframeContext';
import Dashboard from './views/Dashboard';
import { setOrgId, setCountyIds } from "./utils/dashboardUtils";

function App() {

  // for now, commerceorigin can be set to 8080
  //const allowedOrigin = "*";
  // state to track if the message has been sent to the parent application
  const [messageSent, setMessageSent] = useState(false);

  useEffect(() => {

    // If app is loaded within an iframe, send a "ready" message to the parent window
    if (!messageSent && window.parent !== window) {
      window.parent.postMessage({ message: "ready" }, "*");
      setMessageSent(true);
    }

    // Iframe events
    window.addEventListener("message", receiveMessage, false);

    return () => {
      // Cleanup event listener when component unmounts
      window.removeEventListener("message", receiveMessage);
    };
  }, [messageSent]);

  // Receive message - process Iframe requests
  function receiveMessage(event: MessageEvent) {
    event.preventDefault();

    if (event.data.message !== undefined) {
      switch (event.data.message) {
        case "update":
          setOrgId(event.data.params.organization_id || 12);
            
          console.log("Checking for counties: ", event.data);
          // Find counties from geo_details
          const counties = event.data.params.area_view.geo_details?.find((x: any) => x.lod === "county");

          if (counties) {
              console.log("Found counties: ", counties);
              setCountyIds(counties.ids);
          } else {
              // Fallback to geo_tag value if no counties found
              const geoTagValue = event.data.params.area_view.geo_tag?.value;
              console.log("No counties found, using geo_tag value: ", geoTagValue);
              if (geoTagValue) {
                  setCountyIds([geoTagValue]);
              } else {
                  console.log("No geo_tag value found");
                  setCountyIds([]);
              }
          }

          break;
        case "process-token":
          handleToken(event.data.params.token);
          break;
      }
    }
  }

  // handleToken - Process the token received from parent window
  function handleToken(token: any) {
    // Log our token for now
    console.log("Received token:", token);
  };
  return (
    <IframeProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Dashboard/>} />
          {/* Dashboard Content */}
          <Route path="/dashboard/:tab" element={<Dashboard/>}/>
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
    </IframeProvider>
  )
}

export default App
