import { useState } from 'react';
import { BoundingBox, DisaggregatedMetric, GeoDetails, GeoTag, InteractionData, LayerDetail } from '../services/types';
//import { getInteractionsLayerId } from "../utils/dashboardUtils";
import useDeepCompareEffect from './useDeepCompareEffect';
import { orgId } from '../utils/dashboardUtils';

const useFetchAllIndicators = (isOpen: boolean, geoDetails: GeoDetails[], geoTag: GeoTag | null, tabs: string[]) => {
    const [disaggregatedIndicators, setDisaggregatedIndicators] = useState<DisaggregatedMetric[]>([]);
    const [interactionData, setInteractionData] = useState<InteractionData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<Error | null>(null);
    const [boundingBox, setBoundingBox] = useState<BoundingBox | null>(null);
    const [layerDetails, setLayerDetails] = useState<LayerDetail[] | null>(null);
    const [excludeIds, setExcludeIds] = useState<string | null>(null);

    const lowerTabs = tabs.map(tab => tab.toLowerCase());
    const geoEncoding = 'delta';

    const interactionUrl = `${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getinteractionsummary`;
    const indicatorsUrl = `${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getallindicatorsv2`;
    const interactionsLayerIdUrl = `${import.meta.env.VITE_DASHBOARD_BACKEND_URL}/api/getinteractionslayerid`;

    useDeepCompareEffect(() => {
        const handleMessage = async (event: MessageEvent) => {
            console.log('Received message:', event.data);

            if (event.data.message === 'update') {
                const { params } = event.data;

                if (params) {
                    // setOrgId(params.organization_id || 12);
                    // setGeoLod(params.area_view.geo_lod || '');
                    // setGeoIds(params.area_view.geo_ids || []);
                    setBoundingBox(params.area_view.bounding_box);
                    
                    setLayerDetails(params.layer_details || null);


                    // Fetch layer id
                    if(params.organization_id !== null){

                        // Quick hack.. orgId is null for some reason. Fix later :(

                        if (params.organization_id !== 15) {
                            //const layerOrgId = 13; //params.organization_id;
                            const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({ orgId: params.organization_id }),
                            });
        
                            if (!layerIdResponse.ok) {
                                throw new Error('Network response was not ok');
                            }
        
                            const layerIdResult = await layerIdResponse.json();
                            console.log("layerIdResult: ", layerIdResult);
                            const layerId = layerIdResult;
        
                            console.log("LayerId; ", layerId);
        
                            //var layerId = await getInteractionsLayerId();
        
                            const layer = params.layer_details?.find((layer: LayerDetail) => layer.layer_id === layerId) || null;
                            setExcludeIds(layer?.exclude_ids?.join(',') || '');
                        }
                    }
                }
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    useDeepCompareEffect(() => {
        if (!isOpen) return;
        console.log('org id pdf:', orgId);
        const fetchData = async () => {
            try {
                if (orgId !== null) {
                    console.log('Fetching All Indicators...')
                    // Generate an array of fetch promises, one for each category
                    const fetchPromises = lowerTabs.map(category => 
                        fetch(indicatorsUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                geoDetails,
                                orgId,
                                dashboardView: category,
                                geoEncoding,
                                geoTag,
                            }),
                        }).then(response => {
                            if (!response.ok) {
                                throw new Error(`Failed to fetch data for ${category}`);
                            }
                            return response.json();
                        })
                    );
        
                    // Resolve all promises in parallel
                    const results = await Promise.all(fetchPromises);
                    console.log('results:', results);
        
                    // Combine and categorize the results
                    const combinedData = results.flat();
                    setDisaggregatedIndicators(combinedData);

                    // Fetch the interaction layer data for ADOs (skip fetch for Admin view)
                    if (orgId !== 15) {
                        // Fetch layer id
                        const layerIdResponse = await fetch(interactionsLayerIdUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ orgId }),
                        });

                        if (!layerIdResponse.ok) {
                            throw new Error('Network response was not ok');
                        }

                        const layerIdResult = await layerIdResponse.json();
                        console.log("layerIdResult: ", layerIdResult);
                        const layerId = layerIdResult;

                        const intLayer = layerDetails?.find((layer: LayerDetail) => layer.layer_id === layerId) || null;
                        const startDate = intLayer?.start_date;
                        const endDate = intLayer?.end_date;

                        const interactionResponse = await fetch(interactionUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            //body: JSON.stringify({ boundingBox, excludeIds, layerId }),
                            body: JSON.stringify({ boundingBox, excludeIds, layerId, startDate, endDate }),
                        });

                        // Hack to get all interactions so we can calculate how many we are not showing
                        const allInteractionResponse = await fetch(interactionUrl, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ layerId }),
                        });
                        
                        if (!interactionResponse.ok || !allInteractionResponse.ok) {
                            throw new Error('Network response was not ok');
                        }
                        
                        const interactionResult = await interactionResponse.json();
                        const allInteractionResult = await allInteractionResponse.json();

                        // We need to sneak in a parameter to get our 'missing' count
                        console.log("interactionResult: ", interactionResult);
                        if(interactionResult.results > 0){
                            interactionResult.data[0].item_missing_count = allInteractionResult.data[0].item_count - interactionResult.data[0].item_count;
                        }

                        setInteractionData(interactionResult.data);
                    } else {
                        console.log('Skipping interaction data fetch for all ADO view.')
                    }
                }
            } catch (error: any) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [geoDetails, orgId]);

    return { disaggregatedIndicators, interactionData, loading, error };
};

export default useFetchAllIndicators;